@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
body {
  font-family: "Inter", sans-serif;
}
.footer-legal {
  font-size: 10px;
  color: #333;
}
.contenedor-scratch {
  position: relative;
  max-width: 250px;
  height: auto;
  height: 60px;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  padding: 5px;
  /* border: 1px solid red; */
}
.contenedor-scratch::after {
  content: "";
  top: 0px;
  left: 0;
}
