@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
body {
  font-family: "Inter", sans-serif;
}
/* Estilo para simular la pantalla del teléfono */
.phone-frame {
  border-top-left-radius: 55px;
  border-top-left-radius: 55px;
  border-top-right-radius: 55px;
  border-top: 8px solid #393939;
  border-left: 8px solid #393939;
  border-right: 8px solid #393939;
  max-height: 800px;
  max-width: 325px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.phone-notch {
  width: 60px;
  height: 20px;
  background-color: #393939;
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px 10px 10px 10px;
  z-index: 1;
}

.phone-notchii {
  /* border: 1px solid #000; */
  width: 100%;
  height: 60px;
  position: absolute;

  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px 10px 10px 10px;
}

.logo {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
}

.panelblanco {
  background-color: #fff;
  height: 50px;
  position: relative;
}

.footer {
  text-align: center;
  padding: 30px 0;
  color: #000;
}

.footer-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icon {
  display: flex;
  align-items: center;
  margin: 0 10px;
  /* Espacio entre los iconos */
}

.footer-icon img {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

.footer-text {
  margin-bottom: 5px;
}

.footer-legal {
  font-size: 10px;
  color: #333;
}

.footer-link {
  color: #8000ff;
  text-decoration: none;
  font-weight: bold;
}

.footer-link:hover {
  color: #4b0082;
}

.custom-input {
  border-radius: 15px;
  /* Redondeo de bordes */
  border: 2px solid rgb(237, 229, 255);
  /* Color de borde */
  padding: 15px;
  /* Espaciado interno */
  /* box-shadow: 0 0 5px rgba(243, 238, 255, 1); */
  /* Sombra */
  position: absolute;
  top: 12px;
  left: 0;
  z-index: 1;
  background-color: #fff;
  width: 100%;
}

.custom-input-error {
  border-radius: 15px;
  /* Redondeo de bordes */
  border: 2px solid rgb(212, 16, 10);
  /* Color de borde */
  padding: 15px;
  /* Espaciado interno */
  /* box-shadow: 0 0 5px rgba(243, 238, 255, 1); */
  /* Sombra */
  position: absolute;
  top: 12px;
  left: 0;
  z-index: 1;
  background-color: #fff;
  width: 100%;
}

.custom-input:focus {
  border-color: rgb(132, 0, 255, 1);
  /* Color de borde en foco */
  box-shadow: 0 0 0px rgba(132, 0, 255, 1);
  /* Sombra al hacer foco */
  outline: none;
}

.custom-label {
  /* Color de texto */
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  z-index: 2 !important;
  margin-left: 15px;
  text-align: start !important;
  font-size: small;
}

.contenedor-label {
  position: relative;
}

.contenedor-boton {
  position: relative;
  max-width: 325px;
  height: auto;
  background-image: url("../img/BackAmarillo.png");
  background-size: cover;
  overflow: hidden;

  /* border: 1px solid red; */
}

.contenedor-boton::after {
  content: "";
  top: 0px;
  left: 0;
  width: 100%;
  height: 100px;
}

.back-white {
  position: relative;
  max-width: 325px;
  /* border: 1px solid red; */
}

.back-white::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  /* Ajusta la altura del desvanecimiento según sea necesario */
  background: linear-gradient(
    to top,
    rgb(255, 255, 255) 0%,
    rgba(251, 251, 252, 0) 100%
  );
  pointer-events: none;
}

.custom-radio {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.custom-radio input[type="radio"] {
  appearance: none;
  width: 25px;
  height: 25px;
  border: 1px solid #8000ff;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
}

.custom-radio input[type="radio"]:checked::before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #8000ff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.label-titulo {
  font-size: 18px;
  color: #8400ff;
  font-weight: 500;
}

.custom-radio label {
  cursor: pointer;
}

/* Estilos para los checkboxes personalizados */
.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid #8000ff; /* Color morado */
  border-radius: 4px; /* Borde redondeado */
  margin-right: 10px;
  vertical-align: middle;
  background-color: #fff;
  transition: background-color 0.3s, border-color 0.3s;
  position: relative;
}

.custom-checkbox input[type="checkbox"]:checked + label::before {
  background-color: #8000ff; /* Color morado */
  border: 3.5px solid #f5d105; /* Color morado */
}

.custom-checkbox input[type="checkbox"]:checked + label::before::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M20.285 6.708a1 1 0 0 0-1.414 0L9 16.579l-3.871-3.87a1 1 0 0 0-1.414 1.414l4.578 4.578a1 1 0 0 0 1.414 0l10.578-10.578a1 1 0 0 0 0-1.414z"/></svg>')
    no-repeat center center;
  background-size: contain;
}

.label-titulo {
  font-size: 18px;
  color: #8400ff;
  font-weight: 500;
}

.custom-checkbox label {
  cursor: pointer;
  font-size: 15px;
  color: #000;
}
