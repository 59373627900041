@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
body {
    font-family: 'Inter', sans-serif;
}

/* Estilo para simular la pantalla del teléfono */
.phone-frame-home {
    border-top-left-radius: 55px;
    border-top-left-radius: 55px;
    border-top-right-radius: 55px;
    border-top: 8px solid #393939;
    border-left: 8px solid #393939;
    border-right: 8px solid #393939;
    max-height: 380px;
    max-width: 325px;
    background-color: #8400FF;
    overflow: hidden;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gradient-button {
    background: radial-gradient(circle at left, #FFEE00 0%, #FFD700 100%);
    border: none;
    border-radius: 50px;
    color: #9300FF;
    /* Color del texto */
    padding: 15px 40px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s ease;
}

.gradient-button:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
}

.phone-notch {
    width: 60px;
    height: 20px;
    background-color: #393939;
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px 10px 10px 10px;
    z-index: 1;
}

.phone-notchii {
    /* border: 1px solid #000; */
    width: 100%;
    height: 60px;
    position: absolute;

    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px 10px 10px 10px;
}


.logo {
    font-size: 2.5rem;
    font-weight: bold;
    color: #fff;
}

.logo .emoji {
    font-size: 3rem;
    vertical-align: middle;
}

.cta-button {
    background-color: #FFC107;
    color: #4a0081;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 30px;
}

footer {
    font-size: 0.9rem;
    color: #fff;
    margin-top: 20px;
}

.social-icons a {
    font-size: 1.5rem;
    margin: 0 10px;
    color: #fff;
}

.carousel-inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    /* Altura del desvanecimiento */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(132, 0, 255) 100%);
    pointer-events: none;
    /* Para que el gradiente no interfiera con el contenido */
}

.base-logo {
    position: relative;
    height: 40px;
    background-color: #8400FF;
    /* border: 1px solid red; */
}

.base-logo::after {
    content: "";

    top: 0px;
    left: 0;
    width: 100%;
    height: 100px;
    /* Altura del desvanecimiento */
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgb(132, 0, 255) 100%);
    pointer-events: none;
    /* Para que el gradiente no interfiera con el contenido */
}

.panelblanco-home {
    background-color: #fff;
    height: 30px;
    position: relative;
}

.botonamarillo {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);

}

.footer-home {
    text-align: center;
    /* padding: 30px 0; */
    color: #000;
}

.footer-icons-home {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-icon-home {
    display: flex;
    align-items: center;
    margin: 0 10px;
    /* Espacio entre los iconos */
}

.footer-icon-home img {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
}

.footer-text-home {
    margin-bottom: 5px;
}

.footer-legal-home {
    font-size: 10px;
    color: #333;
}

.footer-link-home {
    color: #8000ff;
    text-decoration: none;
    font-weight: bold;
}

.footer-link-home:hover {
    color: #4b0082;
}